<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <div class="d-flex align-center pt-3 flex-md-wrap col-md-9 col-lg-10">
                    <v-text-field
                        :placeholder="$t('message.no')"
                        :value="searchValues.debitNote"
                        autocomplete="new-password"
                        class="filter-width-165 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchValues.debitNote = $event"
                        @click:append="searchDebitNotes('debitNote')"
                        @click:clear="resetSearch('debitNote')"
                        @keyup.enter.prevent="searchDebitNotes('debitNote')"
                    />
                    <v-text-field
                        :placeholder="$t('message.contractNo')"
                        :value="searchValues.contract"
                        autocomplete="new-password"
                        class="filter-width-165 force-text-left ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchValues.contract = $event"
                        @click:append="searchDebitNotes('contract')"
                        @click:clear="resetSearch('contract')"
                        @keyup.enter.prevent="searchDebitNotes('contract')"
                    />
                    <v-select
                        :items="filteredOffices"
                        :placeholder="$t('message.company')"
                        class="filter-width-120 ml-2"
                        autocomplete="off"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Office.id"
                        item-text="Office.code"
                        solo
                        v-model="filterValues.office"
                        @change="filterDebitNotes()"
                    />
                    <v-autocomplete
                        :items="debitNoteSuppliers"
                        :loading="loading.filter.suppliers"
                        :placeholder="$t('message.supplier')"
                        class="filter-width-185 ml-2"
                        autocomplete="off"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Supplier.id"
                        item-text="Supplier.title"
                        solo
                        v-model="filterValues.supplier"
                        @change="filterDebitNotes()"
                    />
                    <div class="col-12 height-10px pa-0" v-if="$vuetify.breakpoint.mdAndDown"></div>
                    <v-autocomplete
                        :items="debitNoteBuyers"
                        :loading="loading.filter.buyers"
                        :placeholder="$t('message.buyers')"
                        :class="'filter-width-185 ml-2' + ($vuetify.breakpoint.lgAndUp ? ' ml-2' : '')"
                        autocomplete="off"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Customer.id"
                        item-text="Customer.title"
                        solo
                        v-model="filterValues.buyer"
                        @change="filterDebitNotes()"
                    />
                    <v-autocomplete
                        :items="allSalesColleagues"
                        :placeholder="$t('message.marketing')"
                        class="filter-width-185 ml-2"
                        autocomplete="off"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Salesperson.id"
                        item-text="Salesperson.name"
                        solo
                        v-model="filterValues.salesColleague"
                        @change="filterDebitNotes()"
                    />
                    <v-select
                        :items="years"
                        :placeholder="$t('message.year')"
                        class="filter-width-100 ml-2"
                        dense
                        hide-details="auto"
                        item-value="value"
                        item-text="text"
                        solo
                        v-model="filterValues.year"
                        @change="filterDebitNotes()"
                    />
                    <v-select
                        :items="months"
                        :placeholder="$t('message.month')"
                        :disabled="filterValues.year == null"
                        class="filter-width-100 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Month.id"
                        item-text="Month.text"
                        solo
                        v-model="filterValues.month"
                        @change="filterDebitNotes()"
                    />
                    <v-btn
                        :loading="loading.clear"
                        class="ml-2 px-2"
                        @click="clearFilters"
                    >{{ $t('message.clear') }}</v-btn>
                </div>
                <div class="d-flex flex-row align-center pt-3 ml-5">
                    <div class="font-sm">
                        <v-icon small color="green lighten-2">fiber_manual_record</v-icon>{{ $t('message.posted') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="red lighten-2">fiber_manual_record</v-icon>{{ $t('message.unposted') }}
                    </div>
                </div>
                <v-spacer></v-spacer>
                <ExportTableJson
                    :export-conditions="exportConditions"
                    :export-data="DebitNotes"
                    :export-fields="headers"
                    :export-source="'debitnotes'"
                    class="ml-3"
                />
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.debitNotes"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
                showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-header="hideDefaultHeader"
            :hide-default-footer="hideDefaultFooter"
            :items="DebitNotes"
            :options="tableOptions"
            :server-items-length="totalDebitNotes"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            id="debitNotesTable"
            item-key="Debitnote.id"
            @update:options="updateDataTable"
        >
            <template v-slot:item.DebitNote.title="{ item }">
                <span class="font-weight-bold ml-0">{{ item.DebitNote.title }}</span>
            </template>
            <template v-slot:item.DebitNote.dndate="{ item }">
                {{ item.DebitNote.dndate != '0000-00-00' ? formatDate(item.DebitNote.dndate) : '' }}
            </template>
            <template v-slot:item.DebitNote.Contract.title="{ item }">
                <span class="text-no-wrap">{{ item.DebitNote.Contract.title }}</span>
            </template>
            <template v-slot:item.DebitNote.Supplier.title="{ item }">
                {{ item.DebitNote.Supplier.otsname != '' ? item.DebitNote.Supplier.otsname : item.DebitNote.Supplier.title }}
            </template>
            <template v-slot:item.DebitNote.Customer.title="{ item }">
                {{ item.DebitNote.Customer.otsname != '' ? item.DebitNote.Customer.otsname : item.DebitNote.Customer.title }}
            </template>
            <template v-slot:item.DebitNote.amount="{ item }">
                <div class="text-left text-no-wrap">{{ getCurrency(item.DebitNote.currency_id) + " " + formatThisNumber(item.DebitNote.amount, currencyFormatPrecision(item.DebitNote.currency_id)) }}</div>
            </template>
            <template v-slot:item.DebitNote.contractAmount="{ item }">
                <div class="text-left text-no-wrap">{{ contractAmount(item) }}</div>
            </template>
            <template v-slot:item.DebitNote.Paymentterm.title="{ item }">
                {{ item.DebitNote.Paymentterm.title }}
            </template>
            <template v-slot:item.DebitNote.Payment.fully_paid_on="{ item }">
                {{ item.DebitNote.Payment.fully_paid_on != '0000-00-00' && item.DebitNote.Payment.fully_paid_on != null  ? formatDate(item.DebitNote.Payment.fully_paid_on) : $t('message.na') }}
            </template>
            <template v-slot:item.DebitNote.is_posted="{ item }">
                <div class="text-center">
                    <v-icon small color="green lighten-2" v-if="item.DebitNote.is_posted == 1">fiber_manual_record</v-icon>
                    <v-icon small color="green lighten-2" v-else>fiber_manual_record</v-icon>
                </div>
            </template>
            <template v-slot:item.DebitNote.posted="{ item }">
                {{ item.DebitNote.posted != '0000-00-00' && item.DebitNote.posted != null  ? formatDate(item.DebitNote.posted) : $t('message.na') }}
            </template>
            <template v-slot:item.DebitNote.id="{ item }">
                <div class="text-center">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="updateDebitNote(item.DebitNote.id)" v-if="$can('update','DebitNote')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateDebitNote') }}</v-list-item>
                            <v-list-item class="font-sm" @click="postDebitNote(item.DebitNote.id)" v-if="false"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.postDebitNoteGp') }}</v-list-item>
                            <v-list-item class="font-sm" @click="cancelDebitNote(item.DebitNote.id, item.DebitNote.title)" v-if="$can('delete','DebitNote')"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelDebitNote') }}</v-list-item>
<!--                            <v-list-item class="font-sm" @click="viewPdf(item.DebitNote.id)" v-if="$can('read','DebitNote')"><v-icon small color="red lighten-1" class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.pdf') }}</v-list-item>-->
                            <PrintDebitNoteButton
                                :update-mode="true"
                                :list-mode="true"
                                :tax-mode="true"
                                :debit-note-id="item.DebitNote.id"
                                v-if="$can('read','DebitNote')"
                            />
                        </v-list>
                    </v-menu>
                </div>
            </template>
            <template v-slot:body.append="{items}">
                <tr class="pb-1">
                    <td colspan="8" class="success lighten-2 text-start"></td>
                    <td class="success lighten-2">
                        <div class="py-1 font-weight-bold text-right" v-for="(amount, index) in totalAmounts">
                            {{ $t('message.total') + ":" }}
                        </div>
                    </td>
                    <td class="success lighten-2 pl-1">
                        <div class="py-1 font-weight-bold text-no-wrap" v-for="(amount, index) in totalAmounts">
                            {{ index + ' ' + formatThisNumber(amount,currencyFormatPrecision(index)) }}
                        </div>
                    </td>
                    <td class="success lighten-2 pl-1">
                        <div class="py-1 font-weight-bold text-no-wrap" v-for="(amount, index) in totalValues">
                            {{ index + ' ' + formatThisNumber(amount,currencyFormatPrecision(index)) }}
                        </div>
                    </td>
                    <td colspan="3" class="success lighten-2 text-start"></td>
                </tr>
            </template>
        </v-data-table>
        <SimpleAlert
            :alert_message.sync="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        ></SimpleAlert>
    </div>
</template>

<script>
    import {formatDate} from "Helpers/helpers";
    // import router from '../../router';
    // import SimpleAlert from "./SimpleAlert";
    import {numberFormat} from "../../helpers/helpers";
    import {mapGetters, mapActions} from "vuex";
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import { v4 as uuidv4 } from 'uuid';

    const SimpleAlert = () => import("Components/Appic/SimpleAlert");
    const ExportTableJson = () => import("Components/Appic/ExportTableJson");
    const PrintDebitNoteButton = () => import("Components/Appic/Printing/PrintDebitNoteButton");

    export default {
        name: "CommissionInvoicesListingNew",
        components: { ExportTableJson, PrintDebitNoteButton, SimpleAlert },
        data() {
            return {
                currentFilter: null,
                currentSearch: null,
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                exportConditions: {},
                filterOptions: {
                    status: {
                        Status: {
                            id: 1,
                            title: 'open'
                        }
                    },
                    // year: new Date().getFullYear()
                    year: null,
                },
                filterValues: {
                    buyer: null,
                    month: null,
                    office: null,
                    salesColleague: null,
                    status: null,
                    supplier: null,
                    year: null
                },
                hideDefaultHeader: false,
                hideDefaultFooter: false,
                loading: {
                    filter: {
                        buyers: false,
                        suppliers: false
                    },
                    search: false,
                    debitNotes: false
                },
                searchField: 'DebitNote.Contract.title',
                searching: false,
                searchTerm: null,
                searchValues: {
                    contract: null,
                    debitNote: null
                },
                tableOptions: {},
            }
        },
        computed: {
            ...mapMultiRowFields('debitnote',{
                DebitNotes: 'current.DebitNotes'
            }),
            ...mapFields('debitnote',{
                debitNoteBuyers: 'current.debitNoteBuyers',
                debitNoteSuppliers: 'current.debitNoteSuppliers',
                totalAmounts: 'current.totalAmounts',
                totalValues: 'current.totalValues',
                totalDebitNotes: 'current.totalDebitNotes'
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('country',{
                allCountries: 'allCountries'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('salescolleague', {
                allSalesColleagues: 'allSalesColleagues'
            }),
            ...mapGetters([
                'currencies',
                'months',
                'offices'
            ]),
            console: () => console,
            defaultItemsPerPage() {
                if(window.screen.height >= 800) return 15
                return 10
            },
            filteredOffices () {
                return this.offices.filter( o => [1,3].includes(o.Office.id))
            },
            formatDate: () => formatDate,
            headers() {
                return [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'DebitNote.id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 1,
                        text: this.$t('message.date'),
                        value: 'DebitNote.dndate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 2,
                        text: this.$t('message.debitNoteNo'),
                        value: 'DebitNote.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: true
                    },
                    {
                        id: 3,
                        text: this.$t('message.status'),
                        value: 'DebitNote.is_posted',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 4,
                        text: this.$t('message.contractNumber'),
                        value: 'DebitNote.Contract.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: true
                    },
                    {
                        id: 5,
                        text: this.$t('message.company'),
                        value: 'DebitNote.Office.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 6,
                        text: this.$t('message.supplier'),
                        value: 'DebitNote.Supplier.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 7,
                        text: this.$t('message.buyer'),
                        value: 'DebitNote.Customer.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 8,
                        text: this.$t('message.salesColleague'),
                        value: 'DebitNote.Salescontact.name',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 9,
                        text: this.$t('message.amount'),
                        value: 'DebitNote.amount',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 10,
                        text: this.$t('message.contractValue'),
                        value: 'DebitNote.contractAmount',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 11,
                        text: this.$t('message.paymentStatus'),
                        value: 'DebitNote.Paymentterm.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 12,
                        text: this.$t('message.paymentDate'),
                        value: 'DebitNote.Payment.fully_paid_on',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 13,
                        text: this.$t('message.datePosted'),
                        value: 'DebitNote.posted',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    }
                ]
            },
            itemsPerPageOptions() {
                let options = [10,20,50,-1];
                if(window.screen.height >= 800){
                    options = [15,30,50,-1];
                }
                return options;
            },
            searchFields() {
                return this.headers.filter((header)=>header.searchable === true)
            },
            years() {
                let years = []
                let dt = new Date()
                let startYear = dt.getFullYear()
                let endYear = 2017
                for(let i = startYear; i >= endYear; i--){
                    years.push({value: i, text: i})
                }
                return years
            }
        },
        methods: {
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('debitnote',{
                cancelDebitNoteById: 'cancelDebitNoteById',
                getAllDebitNotes: 'getAllDebitNotes',
                getAllDebitNoteBuyers: 'getAllDebitNoteBuyers',
                getAllDebitNoteSuppliers: 'getAllDebitNoteSuppliers',
                postDebitNoteById: 'postDebitNoteById',
                searchAllDebitNotes: 'searchAllDebitNotes'
            }),
            async cancelDebitNote(val, title){
                if(await this.$root.$confirm(this.$t('message.cancelDebitNote') + ' ' + title, this.$t('message.confirmations.continueDebitNoteCancelAction'), {color: 'orange'})){
                    this.cancelDebitNoteById(val)
                        .then((response) => {
                            if(response.status == 'success'){
                                this.$toast.success(this.$t('message.successes.debitNoteDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loadDebitNotes()
                            } else {
                                this.$toast.error(this.$t('message.errors.debitNoteNotDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(()=>{
                            this.$toast.error(this.$t('message.errors.debitNoteNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            },
            clearFilters() {
                this.loading.clear = true
                this.filterValues.buyer = null
                this.filterValues.month = null
                this.filterValues.office = null
                this.filterValues.salesColleague = null
                this.filterValues.status = 0
                this.filterValues.year = null
                this.searchValues.contract = null
                this.searchValues.debitNote = null
                this.tableOptions.page = 1
                this.tableOptions.sortBy[0] = 'DebitNote.dndate'
                this.tableOptions.sortDesc[0] = true
                this.loading.clear = false
                this.filterDebitNotes()
            },
            contractAmount(item){
                return this.getCurrency(item.DebitNote.Contract.currency_id) + ' ' + this.formatThisNumber((item.DebitNote.Total.total),this.currencyFormatPrecision(item.DebitNote.Contract.currency_id))
            },
            getCurrency(currency_id) {
                return this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
            },
            currencyFormatPrecision (currency) {
                if(isNaN(currency)){
                    return this.allCurrencies.find((c) => c.Currency.code == currency)?.Currency?.formatPrecision
                } else {
                    return this.allCurrencies.find((c) => c.Currency.id == currency)?.Currency?.formatPrecision
                }
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            filterDebitNotes() {
                this.searchValues.debitNote = null
                this.searchValues.invoice = null
                let noValues = true
                const keys = Object.keys(this.filterValues)
                keys.forEach((key, index) => {
                    if(this.filterValues[key] != null) noValues = false
                })
                if(noValues == false) {
                    let conditions = []
                    if (this.filterValues.buyer != null) conditions.push({
                        field: 'contracts.customer_id',
                        value: this.filterValues.buyer
                    })
                    if (this.filterValues.month != null) conditions.push({
                        field: 'debitnotes.month',
                        value: this.filterValues.month
                    })
                    if (this.filterValues.office != null) conditions.push({
                        field: 'offices.id',
                        value: this.filterValues.office
                    })
                    if (this.filterValues.salesColleague != null) conditions.push({
                        field: 'contracts.salescontact_id',
                        value: this.filterValues.salesColleague
                    })
                    if (this.filterValues.supplier != null) conditions.push({
                        field: 'contracts.supplier_id',
                        value: this.filterValues.supplier
                    })
                    if (this.filterValues.year != null) conditions.push({
                        field: 'debitnotes.year',
                        value: this.filterValues.year
                    })
                    if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage
                    if (_.isEqual(this.currentFilter, this.filterValues) === false) {
                        this.tableOptions.page = 1
                        this.currentFilter = _.cloneDeep(this.filterValues)
                    }
                    if(_.has(this.tableOptions, 'sortBy') == false) {
                        this.tableOptions['sortBy'] = ['DebitNote.dndate']
                    }
                    if(this.tableOptions.sortBy.length == 0){
                        this.tableOptions['sortBy'] = ['DebitNote.dndate']
                    }
                    if(_.has(this.tableOptions, 'sortDesc') == false){
                        this.tableOptions['sortDesc'] = [false]
                        if(this.tableOptions.sortBy[0] == 'DebitNote.dndate') {
                            this.tableOptions['sortDesc'] = [true]
                        }
                    }
                    if(this.tableOptions.sortBy[0] == 'DebitNote.dndate' && this.tableOptions.sortDesc.length == 0){
                        this.tableOptions['sortDesc'] = [true]
                    }
                    let order = [{field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'}]

                    let payload = {
                        tableOptions: this.tableOptions,
                        conditions: conditions,
                        order: order
                    }
                    this.hideDefaultFooter = false
                    this.loading.debitNotes = true;
                    this.searchAllDebitNotes(payload)
                        .then(() => {
                            this.loading.debitNotes = false;
                        })
                        .catch(() => {
                            this.loading.debitNotes = false;
                        })
                        .finally(() => {
                            this.loading.debitNotes = false;
                        })
                } else {
                    this.loadDebitNotes()
                }
            },
            formatThisNumber (value,format) {
                return numberFormat(value,format)
            },
            loadDebitNotes () {
                this.loading.debitNotes = true;
                let payload = {
                    tableOptions: this.tableOptions,
                    filterOptions: this.filterOptions
                }
                this.getAllDebitNotes(payload)
                    .then(()=>{
                        this.loading.debitNotes = false;
                    })
                    .catch(()=>{
                        this.loading.debitNotes = false;
                    })
                    .finally(()=>{
                        this.loading.debitNotes = false;
                    })
            },
            async postDebitNote(){
                if(await this.$root.$confirm(this.$t('message.postInvoice'), this.$t('message.confirmations.postInvoiceCancelAction'), {color: 'orange'})){
                    this.postDebitNoteById(val).then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.debitNotePosted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loadPurchaseOrders()
                        } else {
                            this.$toast.error(this.$t('message.errors.debitNoteNotPosted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                }
            },
            searchDebitNotes ( field ) {
                if(this.searchValues[field] == null){
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.errors.noSearchColumnAndSearchTerm')
                } else {
                    this.searching = true
                    this.filterValues.buyer = null
                    this.filterValues.marketing = null
                    this.filterValues.month = null
                    this.filterValues.office = null
                    this.filterValues.supplier = null
                    this.filterValues.status = null
                    this.filterValues.year = null

                    const keys = Object.keys(this.searchValues)
                    keys.forEach((key, index) => {
                        if(key != field) this.searchValues[key] = null
                    })

                    let fieldName = null
                    let sortFieldName = null
                    switch(field){
                        case 'contract':
                            fieldName = 'DebitNote.Contract.title'
                            sortFieldName = 'Contract.contractdate'
                            break
                        case 'debitNote':
                            fieldName = 'DebitNote.title'
                            sortFieldName = 'DebitNote.dndate'
                            break
                    }

                    this.loading.debitNotes = true
                    let payload = {}
                    let order = []

                    if (_.isEqual(this.currentSearch, this.searchValues) === false) {
                        //new search = new sort
                        order.push({field: fieldName, direction: field == 'debitNote' || field == 'invoice' ? 'ASC' : 'DESC'})
                        this.tableOptions.sortBy[0] = sortFieldName
                        this.tableOptions.sortDesc[0] = field == 'debitNote' || field == 'invoice' ? false : true
                        this.currentSearch = _.cloneDeep(this.searchValues)
                        //new search new page
                        this.tableOptions.page = 1
                    } else {
                        //same search new sort
                        if(this.tableOptions.sortBy.length == 0){
                            this.tableOptions.sortBy[0] = sortFieldName
                        }
                        order.push({field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'})
                    }

                    if(fieldName != null) {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [
                                {
                                    field: fieldName,
                                    value: this.searchValues[field]
                                }
                            ],
                            order: order
                        }
                    } else {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [],
                            order: order
                        }
                    }
                    this.searchAllDebitNotes(payload)
                        .then(() => {
                            this.loading.debitNotes = false;
                        })
                        .catch(()=>{
                            this.loading.debitNotes = false;
                        })
                        .finally(()=>{
                            this.loading.debitNotes = false;
                        })
                }
            },
            resetSearch () {
                this.hideDefaultFooter = false
                this.loading.debitNotes = false
                this.searchField = 'DebitNote.Contract.title';
                this.searchTerm = null;
                this.tableOptions.itemsPerPage = this.defaultItemsPerPage
                this.filterOptions = {
                    status: {
                        Status: {
                            id: 1,
                            title: 'open'
                        }
                    },
                    year: new Date().getFullYear()
                };
            },
            updateDataTable(options) {
                this.tableOptions = options
                if(this.searching === true)  {
                    // check which field is not empty
                    let field = null
                    if(this.searchValues.contract != null) field = 'contract'
                    if(this.searchValues.debitNote != null) field = 'debitNote'
                    this.searchDebitNotes(field)
                } else {
                    this.filterDebitNotes()
                }
            },
            updateDebitNote ( val ) {
                let tab = window.open('/v1/debitnotes/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewPdf ( debitNoteId ) {
                let debitNote = this.DebitNotes.find( dn => dn.DebitNote.id == debitNoteId)
                let debitNoteNumber = debitNote.DebitNote.title
                let contractNumber = [debitNote.DebitNote.Contract.title, debitNote.DebitNote.Contract.revision_no , debitNote.DebitNote.Contract.partial_no].filter(Boolean).join('-')
                contractNumber = contractNumber.replace('.','')
                let document = encodeURIComponent(contractNumber + ' ' + debitNoteNumber)
                // let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/debitnotes/print/'
                    + debitNoteId
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
        },
        watch: {
            // filterOptions: {
            //     handler(){
            //         if(!this.loading.search) {
            //             this.tableOptions.page = 1
            //             this.loadDebitNotes()
            //         }
            //     },
            //     deep: true
            // },
            // tableOptions: {
            //     handler(){
            //         this.loadDebitNotes()
            //     },
            //     deep: true
            // },
        },
        created(){
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
            this.searchValues.contract = null
            this.searchValues.debitNote = null
            this.filterValues.buyer = null
            this.filterValues.month = null
            this.filterValues.office = null
            this.filterValues.salesColleague = null
            this.filterValues.status = 0
            this.filterValues.supplier = null
            this.filterValues.year = null

            if(this.filterValues.status == 0){
                this.filterDebitNotes()
            } else {
                this.loadDebitNotes()
            }
            this.getAllDebitNoteBuyers()
            this.getAllDebitNoteSuppliers()
        },
        // created(){
        //     this.loading.filter.buyers = true
        //     this.getAllDebitNoteBuyers()
        //         .then(() => {
        //             this.loading.filter.buyers = false
        //             this.loading.filter.suppliers = true
        //             this.getAllDebitNoteSuppliers()
        //                 .then(()=> {
        //                     this.loading.filter.suppliers = false
        //                     this.loadDebitNotes()
        //                 })
        //                 .catch(() => {
        //                     this.loading.filter.suppliers = false
        //                 })
        //         })
        //         .catch(() => {
        //             this.loading.filter.buyers = false
        //         })
        // }
    }
</script>

<style>
.filter-width-100 {
    width: 100px !important;
    max-width: 100px !important;
}
.filter-width-120 {
    width: 120px !important;
    max-width: 120px !important;
}
.filter-width-165 {
    width: 165px !important;
    max-width: 165px !important;
}
.filter-width-185 {
    width: 185px !important;
    max-width: 185px !important;
}
.col-0-75 {
    flex: 0 0 6.25%;
    max-width: 6.25%;
}
.col-1-5 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
}
.col-2-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
}
.col-3-5 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
    vertical-align: middle !important;
}
.height-10px {
    height: 10px;
}
</style>